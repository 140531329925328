<template>
  <AddCreditCardMethodLayout
    @onCancel="hideModal"
    @onSaveCard="saveCard"
    :isLoading="isLoading"
  />
</template>

<script>
import AddCreditCardMethodLayout from './layout';
import { mapActions } from 'vuex';

export default {
  name: 'AddCreditCardMethod',
  components: {
    AddCreditCardMethodLayout,
  },

  data() {
    return {
      isLoading: false,
    };
  },

  async mounted() {
    this.$root.$on('ui/hidePrompt', () => {
      this.hideModal();
    });
    await this.$store.dispatch('events/track', {
      event: 'CREDIT_CARD_CREDENTIALS_VIEWED',
    });
  },

  beforeDestroy() {
    this.$root.$off('ui/hidePrompt');
  },

  methods: {
    ...mapActions('wallet', ['createCreditCard']),
    ...mapActions('ui', ['setIsOverlayLoading']),

    async saveCard({ cardHolderName, card, monthExpire, yearExpire }) {
      try {
        this.isLoading = true;
        this.setIsOverlayLoading(true);

        await this.createCreditCard({
          cardHolderName,
          card,
          monthExpire,
          yearExpire,
        });

        this.showModal('PaymentInterface', { isDeposit: true } );
      } catch {
        this.$store.commit('ui/showPrompt', {
          title: this.$t('wallet.modal.add_new_card.error.title'),
          message: this.$t('wallet.modal.add_new_card.error.description'),
          customConfirm: this.$t('wallet.modal.add_new_card.error.try_again'),
        });
      } finally {
        this.isLoading = false;
        this.setIsOverlayLoading(false);
      }
    },
  }
};
</script>

<style scoped>
</style>
