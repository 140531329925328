<template>
  <div class="px-s4 pb-s24 xl:mx-s16">
    <div class="headline-small">{{ $t('wallet.modal.add_new_card.title') }}</div>
    <div class="body-text-large mt-s20 flex">
      <img class="cc-icon" src="@/assets/icons/visa.png" />
      <img class="cc-icon mx-s8" src="@/assets/icons/mastercard.png" />
      {{ $t('wallet.modal.add_new_card.only_visa_mastercard') }}
    </div>

    <CustomInput
      data-cy="input-name"
      data-testid="input-name"
      v-model="cardHolderName"
      class="mt-s24"
      filter="letter"
      :hasError="cardHolderNameInvalid"
      testId="card_holder_name"
      :lp-ignore="false"
    >
      {{ $t('wallet.modal.add_new_card.name') }}
      <template v-slot:error>
        {{ $t('wallet.modal.add_new_card.card_holder_name_invalid') }}
      </template>
    </CustomInput>

    <CustomInput
      v-model="number"
      data-cy="input-number"
      data-testid="input-number"
      class="pt-s8"
      :lp-ignore="false"
      :hasError="cardNumberInvalid"
      inputmode="numeric"
      mask="#### #### #### ####"
      testId="number"
      @maska="rawNumber = $event"
    >
      {{ $t('wallet.modal.add_new_card.card_number') }}
      <template v-slot:error>
        {{ $t('wallet.modal.add_new_card.card_number_invalid') }}
      </template>
    </CustomInput>

    <div class="flex pt-s8">
      <CustomInput
        data-cy="input-expiration"
        data-testid="input-expiration"
        v-model="expiration"
        :lp-ignore="false"
        :hasError="expirationDateIsInvalid"
        inputmode="numeric"
        mask="##/##"
        testId="expiration"
      >
        {{ $t('wallet.modal.add_new_card.month_year') }}
        <template v-slot:error>
          {{ $t('wallet.modal.add_new_card.date_invalid') }}
        </template>
      </CustomInput>

      <CustomInput
        data-cy="input-cvv"
        data-testid="input-cvv"
        v-model="cvv"
        class="flex-1 ml-s16"
        :lp-ignore="false"
        :hasError="cvvInvalid"
        inputmode="numeric"
        mask="####"
        testId="cvv"
        autocomplete="cc-csc"
      >
        {{$t('wallet.modal.add_new_card.cvv')}}
        <template v-slot:error>
          {{ $t('wallet.modal.add_new_card.cvv_invalid') }}
        </template>
      </CustomInput>
    </div>

    <ButtonV2
      data-cy="button-save-card"
      data-testid="button-save-card"
      class="mt-s24"
      @onClick="saveCard"
      :inactive="isFormInvalid || isLoading"
      :label="$tc('wallet.modal.add_new_card.save_card')"
      size="medium"
      wide
    />

    <ButtonV2
      data-cy="button-cancel"
      data-testid="button-cancel"
      class="mt-s16"
      @onClick="$emit('onCancel')"
      :inactive="isLoading"
      :label="$tc('cancel')"
      version="secondary"
      size="medium"
      wide
    />
  </div>
</template>

<script>
import cardValidator from 'card-validator';
import { ButtonV2, CustomInput } from '@/components/misc';

const validCardTypes = ['visa', 'mastercard'];

export default {
  name: 'AddCreditCardMethodLayout',
  components: {
    ButtonV2,
    CustomInput,
  },
  data() {
    return {
      cardHolderName: '',
      cvv: '',
      expiration: '',
      number: '',
      rawNumber: '',
    };
  },
  props: {
    isLoading: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    cardHolderNameInvalid() {
      return this.cardHolderName !== '' && !/^[a-zA-ZÀ-ÿ\u00f1\u00d1]+(?: [a-zA-ZÀ-ÿ\u00f1\u00d1]+ ?)+$/.test(this.cardHolderName);
    },

    cardNumberInvalid() {
      return this.rawNumber !== '' && this.cardNumberPotentiallyInvalid && !this.numberValidation.isValid;
    },

    cardNumberPotentiallyInvalid() {
      return !this.numberValidation.isPotentiallyValid || !validCardTypes.includes(this.cardType);
    },

    cardType() {
      return this.lodashGet(this.numberValidation, 'card.type', '');
    },

    cvvInvalid() {
      return this.cvv !== '' && this.cvv.length < 3;
    },

    expirationDate() {
      const expirationDate = this.expiration.toString().split('/');
      if (expirationDate.length !== 2) {
        return null;
      }
      return { month: expirationDate[0], year: expirationDate[1] };
    },

    expirationDateIsInvalid() {
      if (this.expiration === '') {
        return false;
      }

      if (!this.expirationDate) {
        return true;
      }

      try {
        // Cards expire at the end of the month,
        // thus, cardIsExpiredAt should be the day after the last day of the month written at the card.
        // Users will provide the input from 1-12, and this function expects 0-11,
        // so we can just use the 1-12 number without decreasing 1 to achieve the desired first day of next month as cardIsExpiredAt
        const cardIsExpiredAt = new Date(this.yearExpire, this.monthExpire, 1);
        const currentYear = new Date().getFullYear();
        if (this.yearExpire > currentYear + 15) {
          return true;
        }
        if (this.monthExpire > 12 || this.monthExpire < 1) {
          return true;
        }
        return cardIsExpiredAt < new Date();
      } catch (err) {
        return true;
      }
    },

    isFormInvalid() {
      return !this.cardHolderName ||
        this.cardHolderNameInvalid ||
        this.cardNumberInvalid ||
        !this.cvv ||
        this.cvvInvalid ||
        !this.expiration ||
        this.expirationDateIsInvalid ||
        this.rawNumber.length !== 16;
    },

    monthExpire() {
      return parseInt(this.expirationDate.month);
    },

    numberValidation() {
      return cardValidator.number(this.rawNumber);
    },

    yearExpire() {
      return parseInt(this.expirationDate.year) + 2000; // this thing will stop working at year 3000, sorry
    },
  },
  methods: {
    saveCard() {
      this.$emit('onSaveCard', {
        cardHolderName: this.cardHolderName,
        card: {
          number: this.rawNumber,
          cvv: this.cvv
        },
        monthExpire: this.monthExpire,
        yearExpire: this.yearExpire,
      });
    },
  }
};
</script>

<style scoped>
.cc-icon {
  height: 20px;
}
</style>
